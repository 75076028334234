@import "../../global";

.header {
  padding: 45px $gut-width 0 $gut-width;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: $bg-secondary;

  &.border {
    border-bottom: 1px solid $rule;
  }

  .eyebrow {
    @include eyebrow;
  }

  .title {
    @include title-large;
    margin-bottom: 12px;
  }

  .subtext {
    font-size: 1.8rem;
    line-height: 3.2rem;
    margin-bottom: 12px;
  }

  .links {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    .anchor {
      font-size: 1.6rem;
      line-height: 2.0rem;
      color: $text-secondary;
      font-weight: bold;
      margin-right: 24px;
      padding: 12px 7.5px;

      &:hover {
        color: $accent-primary;
      }

      &.active {
        color: $text-primary;
        border-bottom: 3px solid $accent-primary;
      }
    }
  }
}
