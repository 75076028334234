@import '../../global';

.container {
  display: flex;
  flex-direction: row;

  .datePicker {
    color: $accent-primary;
    text-align: right;
    background: none;
    border: none;
    font-size: 1.6rem;
    font-weight: 200;
    line-height: 2.8rem;
    text-decoration: underline;
    padding: 0;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }
  .withTimezone {
    width: 70%;
  }
  .timezoneSelect {
    min-width: 82px;
    width: 82px;
    margin-left: 10px;
  }
}
